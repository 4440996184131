import React from 'react'

const HeadingSection = () => {
  return (
    <section className="heading" id="heading">
        <div className="c-grid">
          <div className="heading__wrapper">
            <div>
              <div className="heading__blockquote">
                <h1>At last, the garbage reached so high • That it finally touched the sky. • And all the neighbors moved away, • And none of her friends would come to play. • And finally, Sarah Cynthia Stout said, o "OK, I'll take the garbage out!"</h1>
                <h2>Shel Silverstein, 1974</h2>
              </div>
            </div>
          </div>
        </div>
    </section>
  )
}


export default HeadingSection
