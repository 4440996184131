import React, { useState } from 'react'
import { useHistory, useLocation, Link } from 'react-router-dom'
import styled from 'styled-components'

const AboutSection = () => {
  return (

    <section className="about" id="about">
        <div className="c-grid">
          <h2 className="about__title c-title">About</h2>
          <div className="about__wrapper">
            <div><img className="about__media" src="img/img-about.png" alt=""/></div>
            <div>
              <p className="about__desc"><strong>(re)demption</strong> is a public utility which uses the basic principles of blockchain technology to create and maintain a chain of responsibility spanning a product's life cycle. It ensures a producer who benefits from creating and selling a product in the marketplace bears the debt of collection and disposal of that product when its useful existence is complete.</p>
              <p className="about__desc">The protocol's smart contract allows producers to fund in escrow a bounty which recycling providers can access when a product reaches end-of-life. If the provider meets the specific terms of the contract each redemption of an asset triggers a disbursement of funds.</p>
              <p className="about__desc">Open sourced and decentralized it is an extended producer responsibility platform that brings all stakeholders together under an aligned incentive structure with low costs of verification and unlimited ability to scale.</p>
              <p className="about__desc">The outcome is a protocol for turning trash into treasure, helping society solve the pressing need for a global and effective circular economy.</p>
            </div>
          </div>
        </div>
      </section>   
  )
}


export default AboutSection
