import React, { useState, useEffect } from 'react'
import { useDisclosure } from "@chakra-ui/react";

import AccountModal from "../../../components/AccountModal";




interface Props {
    // escrowCount: number;
    // escrowBlockchain: any;
    account: any;
    // actionEnabled: boolean;
    // walletconnection: () => void;	
    activeProducerArray: any;
    activeProviderArray: any;
    // activeAssetTypesArray: any;
    // setTxStatus: (transactionStatus: string) => void;	
    txStatus: string;
  }

// const CategorySection = () => {
const CategorySection: React.FC<Props> = ({ activeProducerArray, activeProviderArray,account, txStatus}) => {

    const { isOpen, onClose } = useDisclosure();  
    const [assetsFunded, setAssetsFunded] = useState('0');
    const [assetsTotalFundedAmount, setAssetsTotalFundedAmount] = useState('0');
    const [escrowOutstanding, setEscrowOutstanding] = useState('0');
    const [totalRebateReceived, setTotalRebateReceived] = useState('0');


  // const history = useHistory();


  useEffect(()=>{

        console.log("called in dashboard to fetch data");
		if(activeProducerArray){
			let _assetsFunded: number = 0;
			let _assetsTotalFundedAmount: number = 0;
			let _escrowOutstanding: number = 0;
			let _totalRebateReceived: number = 0;

			activeProducerArray.map((producerInfo:any) => {
				// if(producerInfo['producer'] == account){
					// _assetsFunded = _assetsFunded+(parseInt(producerInfo['quantity']))
					_assetsFunded = _assetsFunded+(parseInt(producerInfo['quantity']) - parseInt(producerInfo['redeemedQuantity']))
					// _assetsTotalFundedAmount = _assetsTotalFundedAmount + (parseInt(producerInfo['amount'])/1000000)
					_assetsTotalFundedAmount = _assetsTotalFundedAmount + (parseInt(producerInfo['remainingAmount'])/1000000)

          // ETH version
					// _escrowOutstanding = _escrowOutstanding + (parseInt(producerInfo['remainingAmount'])/1000000000000000000)
					// _totalRebateReceived = _totalRebateReceived + (parseInt(producerInfo['redeemedAmount'])/1000000000000000000)

          // USDT version
					_escrowOutstanding = _escrowOutstanding + (parseInt(producerInfo['remainingAmount'])/1000000)
					_totalRebateReceived = _totalRebateReceived + (parseInt(producerInfo['redeemedAmount'])/1000000)
				// }                
			});
			
			setAssetsFunded(String(_assetsFunded.toFixed(0)));
			setAssetsTotalFundedAmount(String(_assetsTotalFundedAmount.toFixed(0)));
			setEscrowOutstanding(String(_escrowOutstanding.toFixed(5)));
			setTotalRebateReceived(String(_totalRebateReceived.toFixed(1)));
		}else {
			setAssetsFunded("0");
			setAssetsTotalFundedAmount("0");
			setEscrowOutstanding("0");
			setTotalRebateReceived("0");
		}      

    }, [activeProviderArray,account,activeProducerArray])

  return (
    <section className="category" id="category">
        <div className="c-grid">
            <div className="category__wrapper">
            <div className="category__box-wrapper">
                <div className="category__box">
                <div>
                    <h2>Total Assets in Escrow</h2>
                    <h3>{assetsFunded}</h3>
                </div>
                </div>
                <div className="category__box">
                <div>
                    <h2>Total Redemption Value</h2>
                    <h3>{assetsTotalFundedAmount} USDT</h3>
                </div>
                </div>
                <div className="category__box">
                <div>
                    <h2>Total Value Redeemed</h2>
                    <h3>{totalRebateReceived} USDT</h3>
                </div>
                </div>
            </div>
            </div>
        </div>
        <AccountModal isOpen={isOpen} onClose={onClose}  txStatus={txStatus}/>
    </section>
  )
}


export default CategorySection
