import React, { useState } from 'react'
import { useHistory, useLocation, Link } from 'react-router-dom'
import styled from 'styled-components'


const HomeHeader = () => {


  return (

    // <section classNameName="header header--subheader">
    //   <div classNameName="c-grid-fluid">
    //     <div classNameName="header__wrapper">
    //       <div>
    //         <div classNameName="logo logo--header"><a classNameName="logo__btn" href="/"><img classNameName="logo__img" src="img/logo.png" alt=""/>
    //             <u classNameName="logo__divider"></u>
    //             <div classNameName="logo__content"><span classNameName="logo__slogan">A public utility protocol for turning trash into treasure.</span>
    //             </div></a>
    //         </div>
    //       </div>
    //       <div>
    //         <nav classNameName="header__nav"><a href="/refund">PRODUCERS</a><a href="redeem">PROVIDERS</a><a href="/review">REGULATORS</a>
    //           <div classNameName="c-btn__wrapper"><a classNameName="c-btn" href="#app">App</a></div>
    //         </nav>
    //       </div>
    //     </div>
    //   </div>
    // </section>


    <nav className="header" id="header">
      <div className="c-grid-fluid">
        <div className="header__wrapper">
          <div>
            <div className="logo logo--header">
              <a className="logo__btn" href="/">
                <img className="logo__img" src="img/logo.png" alt=""/>
                <u className="logo__divider"/>
                <div className="logo__content">
                  <img className="logo__subimg" src="img/sub-logo.png" alt=""/>
                  <span className="logo__slogan">A public utility protocol for turning trash into treasure.</span>
                </div>
              </a>
            </div>
            <div className="hamburger hamburger--squeeze" role="button" hamburger-js="hamburger-js">
              <div className="hamburger-box">
                <div className="hamburger-inner"></div>
              </div>
            </div>
          </div>
          <div mobile-block-js="mobile-block-js">
            <nav className="header__nav">
              <a href="#about">About</a>
              <a href="https://re-demption.gitbook.io/re-demption/">White Paper</a>
              <a href="mailto:matt@re-demption.xyz">Contact</a>
              <div className="c-btn__wrapper">
                <a className="c-btn" href="https://app.re-demption.xyz/">App</a>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </nav>
  )
}


export default HomeHeader
